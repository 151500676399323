<template>
    <div class="container">
        <h1>Ace editor</h1>
        <hr>
        <div id="editor">function foo(items) {
            var x = "All this is syntax highlighted";
            return x;
        }</div>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        
    },
    created(){
        let aceEditorScript = document.createElement('script')
        aceEditorScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/chart.js@3/dist/chart.min.js')
        document.head.appendChild(aceEditorScript)

    },
    mounted(){
        // var editor = ace.edit("editor");
        // editor.setTheme("ace/theme/monokai");
        // editor.session.setMode("ace/mode/latex");
        
    }
    
}
</script>